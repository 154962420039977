import React from "react";

/*import img1 from '../../media/images/carousel/23.png'
import img2 from '../../media/images/carousel/24.png'
import img3 from '../../media/images/carousel/25.png'
import img4 from '../../media/images/carousel/26.png'
import img5 from '../../media/images/carousel/27.png'
import img6 from '../../media/images/carousel/28.png'
import img7 from '../../media/images/carousel/29.png'
import img8 from '../../media/images/carousel/30.png'
import img9 from '../../media/images/carousel/31.png'
import img10 from '../../media/images/carousel/32.png'
import img11 from '../../media/images/carousel/33.png'
import img12 from '../../media/images/carousel/34.png'
import img13 from '../../media/images/carousel/35.png'
import img14 from '../../media/images/carousel/36.png'
import img15 from '../../media/images/carousel/37.png'
import img16 from '../../media/images/carousel/38.png'
import img17 from '../../media/images/carousel/39.png'
import img18 from '../../media/images/carousel/40.png'
import img19 from '../../media/images/carousel/41.png'
import img20 from '../../media/images/carousel/42.png'
import img21 from '../../media/images/carousel/43.png'
import img22 from '../../media/images/carousel/44.png'
import img23 from '../../media/images/carousel/45.png'
import img24 from '../../media/images/carousel/46.png'
import img25 from '../../media/images/carousel/47.png'
import img26 from '../../media/images/carousel/48.png'
import img27 from '../../media/images/carousel/49.png'
import img28 from '../../media/images/carousel/50.png'
import img29 from '../../media/images/carousel/51.png'
import img30 from '../../media/images/carousel/52.png'*/


import "./Carousel.scss";



//const imgArr = [img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22,img23,img24,img25,img26,img27,img28,img29,img30]

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context("../../media/images/carousel/", true, /\.(png|jpe?g|svg)$/));
const slides = images.map((item, index) => ({
  title: "Criminal Raccoon #"+index,
  subtitle: "Prison V.L.",
  description: "",
  image: item,
})) /*[{
  title: "Criminal Raccoon #"+1,
  subtitle: "Prison V.L.",
  description: "",
  image: img1,
},{
  title: "Criminal Raccoon #"+1,
  subtitle: "Prison V.L.",
  description: "",
  image: img2,
},{
  title: "Criminal Raccoon #"+1,
  subtitle: "Prison V.L.",
  description: "",
  image: img3,
},{
  title: "Criminal Raccoon #"+1,
  subtitle: "Prison V.L.",
  description: "",
  image: img4,
}]*/
  

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: Math.trunc(slides.length/2),
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % slides.length,
    };
  }
  if (event.type === "PREV") {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1,
    };
  }
};

function Slide({ slide, offset, onClick }) {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      <div
        className="slideBackground"
        style={{
          backgroundImage: `url('${slide.image}')`,
        }}
      />
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${slide.image}')`,
        }}
        onClick={onClick}
      >
        {/*<div className="slideContentInner">
          <h2 className="slideTitle">{slide.title}</h2>
          <h3 className="slideSubtitle">{slide.subtitle}</h3>
          <p className="slideDescription">{slide.description}</p>
      </div>*/}
      </div>
    </div>
  );
}

function Carousel() {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);

  return (
    <div className="slides">
      <button onClick={() => dispatch({ type: "NEXT" })}>‹</button>

      {[...slides, ...slides, ...slides].map((slide, i) => {
        let offset = slides.length + (state.slideIndex - i);
        return <Slide slide={slide} onClick={() => dispatch({ type: "PREV" })} offset={offset} key={i} />;
      })}
      <button onClick={() => dispatch({ type: "PREV" })}>›</button>
    </div>
  );
}

export default Carousel;
