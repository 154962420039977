import React from "react";
import Title from "../../components/Title/Title";
import Context from "../../Context";

import "./Vip.scss";

export default function Vip() {
  const [vip, setVip] = React.useState([
    {
      id: 1,
      name: "HSLM",
      raccoon: "#10",
      link: "https://opensea.io/HSLM",
    },
  ]);
  const {t} = React.useContext(Context)

  return (
    <div className="container">
      <Title text={t("header.vip2")} />
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-1">ID</div>
          <div className="col col-2">VIP Raccoon Name</div>
          <div className="col col-3">ID Raccoon</div>
          <div className="col col-4">Link</div>
        </li>
        {vip.map((item: any) => (
          <li className="table-row">
            <div className="col col-1" data-label="ID">
              {item.id}
            </div>
            <div className="col col-2" data-label="VIP Raccoon Name">
              {item.name}
            </div>
            <div className="col col-3" data-label="ID Raccoon">
              {item.raccoon}
            </div>
            <div className="col col-4" data-label="Link">
              {item.link ? <a target="_blank" href={item.link}>OpenSea</a> : ""}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
