import React from "react";
import "./App.scss";
import { useMetaMask } from "metamask-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About/About";
import RoadMap from "./pages/RoadMap/RoadMap";
import Faq from "./pages/FAQ/Faq";
import Header from "./components/Header/Header";
import Context from "./Context";
import { Divider } from "antd";

import { useTranslation } from "react-i18next";

import "antd/dist/antd.min.css";
import Footer from "./components/Footer/Footer";
import Vip from "./pages/Vip/Vip";


function App() {
  const { connect, status, account } = useMetaMask();
  const { t, i18n }: any = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Context.Provider value={{ t, i18n }}>
      <div className="body">
        <Router>
          <Header
            status={status}
            connect={connect}
            account={account}
            changeLang={changeLanguage}
          />
          <div className="body-divider">
            <Divider style={{ backgroundColor: "#aaa" }} />
          </div>
          <div className="body-content">
            <Routes>
              <Route path="/" element={<About />} />
              <Route path="/about" element={<About />} />
              <Route path="/roadmap" element={<RoadMap />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/vip" element={<Vip />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </div>
    </Context.Provider>
  );
}

export default App;
