import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Title from "../../components/Title/Title";
import Context from "../../Context";
import "./RoadMap.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import "swiper/scss/a11y";
import { Progress } from "antd";

export default function RoadMap() {
  const { t } = React.useContext(Context);
  const [progr, setProgr] = React.useState(1/10000);

  return (
    <div className="roadmap">
      <Title text={t("header.roadmap")} />
      <div className="roadmap-grid">
        <div className="swiper-content">
          <Title text={t("roadmap.roadstart")} />
          <ol className="rounded">
            <li>
              <a data-content="1" href="#">
                {t("roadmap.0-1")}
              </a>
            </li>
            <li>
              <a data-content="2" href="#">
                {t("roadmap.0-2")}
              </a>
            </li>
          </ol>
        </div>
        <div className="swiper-content progr">
          <Progress
            type="circle"
            width={300}
            strokeWidth={15}
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
            percent={progr}
          />
        </div>
        <div className="swiper-content">
          <Title text={"20% " + t("roadmap.sale")} />
          <ol className="rounded">
            <li>
              <a data-content="3" href="#">
                {t("roadmap.15-1")}
              </a>
            </li>
            <li>
              <a data-content="4" href="#">
                {t("roadmap.15-2")}
              </a>
            </li>
          </ol>
        </div>
      </div>
      <div className="roadmap-grid" style={{ justifyContent: "center" }}>
        <div className="swiper-content">
          <Title text={"50% " + t("roadmap.sale")} />
          <ol className="rounded">
            <li>
              <a data-content="5" href="#">
                {t("roadmap.30-1")}
              </a>
            </li>
          </ol>
        </div>
      </div>
      <div className="roadmap-grid">
        <div className="swiper-content">
          <Title text={"75% " + t("roadmap.sale")} />
          <ol className="rounded">
            <li key={3}>
              <a data-content="6" href="#">
                {t("roadmap.70-1")}
              </a>
            </li>
            <li>
              <a data-content="7" href="#">
                {t("roadmap.70-2")}
              </a>
            </li>
          </ol>
        </div>
        <div className="swiper-content progr">
          <Progress
            type="circle"
            width={300}
            style={{color: "white"}}
            strokeWidth={15}
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
            percent={progr}
          />
        </div>
        <div className="swiper-content">
          <Title text={"100% " + t("roadmap.sale")} />
          <ol className="rounded">
            <li>
              <a data-content="8" href="#">
                {t("roadmap.100-1")}
              </a>
            </li>
          </ol>
        </div>
        <div className="swiper-content progr-mob">
          <Progress
            type="circle"
            //width={250}
            style={{color: "white", height: "100%"}}
            strokeWidth={15}
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
            percent={progr}
          />
        </div>
      </div>
    </div>
  );
}
