import React from "react";
import "./About.scss";
import Context from "../../Context";
import Title from "../../components/Title/Title";
import Carousel from "../../components/Carousel/Carousel";

export default function About() {
  const { t } = React.useContext(Context);

  return (
    <div className="about">
      <Title text={t("header.about")} />
      <div className="about-content">
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
            <p>{t("about.main1")} {t("about.main2")}</p>
            <p>{t("about.main3")}</p>
        </div>
          <Carousel />
      </div>
    </div>
  );
}
