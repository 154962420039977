import React from "react";

import "./Header.scss";
import { Link } from "react-router-dom";
import { Dropdown, Menu, notification } from "antd";
import Context from "../../Context";
import MetaMask from "../../media/images/metamask.svg";
import Logo from "../Logo/Logo";

interface Props {
  status: string;
  connect: () => void;
  account: null | string;
  changeLang: (l: string) => void;
}

export default function Header({
  status,
  connect,
  account,
  changeLang,
}: Props) {
  const [lang, setLang] = React.useState<string>();
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setLang("RU");
          changeLang("ru");
        }}
        key="1"
      >
        RU
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setLang("EN");
          changeLang("en");
        }}
        key="2"
      >
        EN
      </Menu.Item>
    </Menu>
  );

  React.useEffect(() => {
    if (localStorage.hasOwnProperty("i18nextLng")) {
      setLang(localStorage.i18nextLng.toUpperCase());
      changeLang(localStorage.i18nextLng);
    } else {
      setLang("EN");
      changeLang("en");
    }
  }, []);

  const { t, i18n } = React.useContext(Context);

  const openNotification = () => {
    notification.open({
      message: t("service_title"),
      description: (
        <span>
          {t("service_message")}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
          >
            MetaMask
          </a>
        </span>
      ),
      onClick: () => {
        console.log("Notification Clicked!");
      },
      duration: 5,
    });
  };

  React.useEffect(() => {
    setLang(i18n.options.fallbackLng[0].toUpperCase());
  }, [i18n]);

  return (
    <div className="header">
        <Logo />
      <div className="header-navigation">
        <Link to="/about">
          <span>{t("header.about")}</span>
        </Link>
        <Link to="/faq">
          <span>{t("header.faq")}</span>
        </Link>
        <Link to="/roadmap">
          <span>{t("header.roadmap")}</span>
        </Link>
        <Link to="/vip">
          <span>{t("header.vip")}</span>
        </Link>
        {status !== "connected" ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              status === "unavailable" ? openNotification() : connect();
            }}
          >
            {t("header.signin")}
          </span>
        ) : (
          <>
            <span style={{ cursor: "default" }}>
              <img alt="MetaMask" style={{ marginRight: 5 }} height={16} src={MetaMask} />
              {t("header.profile")}
            </span>
          </>
        )}
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" href="lang"  onClick={(e) => e.preventDefault()}>
            {localStorage?.i18nextLng.toUpperCase() || lang}
          </a>
        </Dropdown>
      </div>
    </div>
  );
}
