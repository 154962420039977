import React from "react";
import { Collapse } from "antd";
import "./Faq.scss";
import Context from "../../Context";
import Title from "../../components/Title/Title";

const { Panel } = Collapse;

export default function Faq() {
  const { t } = React.useContext(Context);

  return (
    <div className="faq">
      <Title text={t("header.faq")} />
      <div className="faq-content">
        <Collapse accordion collapsible="header" style={{ backgroundColor: "inherit", width: "100%" }}>
          <Panel
            className="faq-panel"
            header={t("faq.question1")}
            showArrow={false}
            key="1"
            
          >
            <p>{t("faq.response1")}</p>
          </Panel>
          <Panel
            className="faq-panel"
            header={t("faq.question2")}
            showArrow={false}
            key="2"
          >
            <p>{t("faq.response2")}</p>
          </Panel>
          <Panel
            className="faq-panel"
            header={t("faq.question3")}
            showArrow={false}
            key="3"
          >
            <p>{t("faq.response3")}</p>
          </Panel>
          <Panel
            className="faq-panel"
            header={t("faq.question4")}
            showArrow={false}
            key="4"
          >
            <p>{t("faq.response4")}</p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}
