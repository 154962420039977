import React from 'react';
import './Title.scss'

export default function Title({text}:any) {

    /*const [title, setTitle] = React.useState("")

    React.useEffect(() => {
        if(text) setTitle(text)
    }, [text])*/

  return <div className='title'><span>{text}</span></div>;
}
