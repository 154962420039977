import { Divider } from "antd";
import React from "react";
import Logo from "../Logo/Logo";
import "./Footer.scss";
import Discord from "../../media/images/discord.png";
import Opensea from "../../media/images/opensea.png";
import Twitter from "../../media/images/twitter.png";
import Context from "../../Context";
import {TwitterOutlined} from  '@ant-design/icons';

export default function Footer() {
  const { t } = React.useContext(Context);
  return (
    <div className="footer">
      <div className="footer-content">
        <Divider style={{ backgroundColor: "#aaa" }} />
        <div className="footer-content-body">
          <Logo />
          <a target="_blank" href="https://discord.gg/z5vN2JGumB">
            <img height={48} src={Discord} />
            <span style={{ fontSize: 24, marginLeft: 10 }}>Discord</span>
          </a>
          <a
            target="_blank"
            href="https://opensea.io/collection/criminalraccoons"
          >
            <img height={48} src={Opensea} />{" "}
            <span style={{ fontSize: 24, marginLeft: 10 }}>OpenSea</span>
          </a>
          <a
            target="_blank"
            href="https://twitter.com/CriminalRaccoo1"
          >
            <img height={48} src={Twitter} />{" "}
            <span style={{ fontSize: 24, marginLeft: 10 }}>Twitter</span>
          </a>
          <div className="footer-content-body-copyright">
            <span style={{ fontSize: 24 }}>{t("company")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
